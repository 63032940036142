"use client";

import { vercelHttpBatchLink } from "@/lib/utils";
import { type AppRouter } from "@/server";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTRPCReact } from "@trpc/react-query";
import React from "react";
export const trpc = createTRPCReact<AppRouter>({});
const queryClient = new QueryClient();
const trpcClient = trpc.createClient({
  links: [vercelHttpBatchLink]
});
export default function TrpcProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return <trpc.Provider client={trpcClient} queryClient={queryClient} data-sentry-element="unknown" data-sentry-component="TrpcProvider" data-sentry-source-file="TrpcProvider.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="TrpcProvider.tsx">{children}</QueryClientProvider>
    </trpc.Provider>;
}