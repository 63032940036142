"use client";

import { Toaster } from "@/components/ui/sonner";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import TrpcProvider from "./TrpcProvider";
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: "identified_only" // or 'always' to create profiles for anonymous users as well
  });
}
const AppProvider: React.FC<{
  children: any;
}> = ({
  children
}) => {
  return <TrpcProvider data-sentry-element="TrpcProvider" data-sentry-component="AppProvider" data-sentry-source-file="app.tsx">
      <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-source-file="app.tsx">{children}</PostHogProvider>
      <Toaster data-sentry-element="Toaster" data-sentry-source-file="app.tsx" />
    </TrpcProvider>;
};
export default AppProvider;