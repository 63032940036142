import { httpBatchLink } from "@trpc/client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import superjson from "superjson";
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const cx = cn;

export const vercelHttpBatchLink = httpBatchLink({
  transformer: superjson,
  url: process.env.NEXT_PUBLIC_SERVICE_URL! + "/api/trpc",
});

export const generateCode = (
  { length }: { length: number } = { length: 6 }
) => {
  return Array.from({ length: length }, () =>
    String.fromCharCode(65 + Math.floor(Math.random() * 26))
  ).join("");
};

export const getNextQuarter = (currentQuarter: string) => {
  // currentQuarter is in the format of QX'YY
  const quarter = parseInt(currentQuarter.slice(1, 2));
  const year = parseInt(currentQuarter.slice(3, 5));
  let nextQuarter = quarter + 1;
  const nextYear = nextQuarter > 4 ? year + 1 : year;
  nextQuarter = nextQuarter > 4 ? 1 : nextQuarter;
  return `Q${nextQuarter}'${nextYear}`;
};

export const errorHandledRequest = async <T>(
  request: Promise<T>
): Promise<T | undefined> => {
  try {
    // const rnd = Math.random();
    // if (rnd < 1) {
    //   console.error("Something went wrong");
    //   throw new Error("Something went wrong");
    // }
    return await request;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const settleAllPromises = async <T extends unknown[]>(
  promises: [...{ [K in keyof T]: Promise<T[K]> }]
): Promise<T> => {
  const values = await Promise.allSettled(promises.map(errorHandledRequest));
  return values.map((value) =>
    value.status === "fulfilled" ? value.value : undefined
  ) as T;
};
